// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Components
import Ticker from 'react-ticker';

const TickerComponent = ({ text = '', theme, locale }) => {
    // Global state
    const [focusedHeader] = useGlobal('focusedHeader');
    const [mobileNavigationActive] = useGlobal('mobileNavigationActive');
    const [globalTicker] = useGlobal('globalTicker');

    const tickerText =
        globalTicker && globalTicker[locale]
            ? globalTicker[locale]
            : text
            ? text
            : '';

    return tickerText.length > 0 ? (
        <>
            <TickerWrapper {...{ theme }}>
                <Ticker
                    move={!focusedHeader && !mobileNavigationActive}
                    speed={3}>
                    {() => <TickerText>{tickerText}</TickerText>}
                </Ticker>
            </TickerWrapper>
        </>
    ) : null;
};

const TickerWrapper = styled.div`
    left: 0;
    right: 0;
    position: absolute;
    bottom: 1.5em;
    overflow: hidden;
    color: ${props => props.theme.text()};
`;

const TickerText = styled.span`
    ${s.fonts.style('h11')};
    margin: 0 2em;
`;

export default TickerComponent;
